import React from 'react'
import { css } from '@emotion/core'
import styled from '@emotion/styled'
import { colors, breakpoints, sizes, fonts } from '../../../styles/variables'

export default function Contact() {
    return (
        <Div>
            <form
                className='contact-form'
                method='post'
                data-netlify='true'
                name='contact'
                data-netlify-honeypot='bot-field'
                action='/thank-you'
            >
                <input type='hidden' name='form-name' value='contact'/>
                <input type='hidden' name='bot-field'/>
                <div className='form-block'>
                    <div className='input-block'>
                        <label>Your Name:</label>
                        <input name='name' type='text' required/>
                    </div>
                    <div className='input-block'>
                        <label>Your Email Address:</label>
                        <input name='email' type='email' required/>
                    </div>
                </div>
                <div className='text-block'>
                    <label>Your Message:</label>
                    <textarea name='message' required/>
                </div>
                <input className='theme' type='submit' value='Send'/>
            </form>
        </Div>
    )
}

const Div = styled.div(props => {
    return css`
        max-width: ${sizes.constrainWidth};
        padding: 0 0.5rem;
        margin: auto;
        @media(${breakpoints.phoneLarge}) {
            padding: 0 3rem;
        }
        form {
            display: flex;
            flex-direction: column;
            textarea {
                min-height: 15vh;
                max-height: 30vh;
                resize: vertical;
                overflow-y: auto;
            }
            input, textarea {
                width: 100%;
                padding: 5px;
                font-size: 1rem;
                font-family: ${fonts.secondary};
                border: 2px solid ${colors.secondary}
            }
            input[type='submit'] {
                margin: auto;
                margin-top: 10px;
                height: 45px;
                width: 100px;
                font-weight: 600;
                font-size: 1.15rem;
                border: 2px solid ${colors.secondary};
                border-radius: 7.5px;
                cursor: pointer;
            }
            .form-block {
                display: flex;
                flex-direction: column;
                flex-wrap: wrap;
                width: 100%;
                .input-block {
                    width: 100%;
                }
                @media(${breakpoints.tablet}) {
                    flex-direction: row;
                    .input-block {
                        width: 50%;
                    }
                    .input-block:first-of-type {
                        padding-right: 20px;
                    }
                }
            }
            .input-block, .text-block {
                display: flex;
                flex-direction: column;
                flex-wrap: wrap;
                padding: 0 0 10px 0;
                width: 100%;
            }
        }
        p {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
        }
    `
})