import React, { useState } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { css } from '@emotion/core'
import styled from '@emotion/styled'
import Popup from 'reactjs-popup'
import File from './file'
import { colors, breakpoints, sizes } from '../../styles/variables'
import { sanityFile } from '../../../../utils'

import 'reactjs-popup/dist/index.css'

const query = graphql`
  query {
    allSanityFileAsset {
      nodes {
        assetId
        url
        originalFilename
      }
    }
  }
`

export default function FileModal(props) {
  const { fileName, fileEntry } = props?.node
  const file = sanityFile(fileEntry?.asset?._ref || fileEntry?.asset?.id, useStaticQuery(query))

  const [open, setOpen] = useState(false)

  return (
    file &&
    <Div>
        <div className='desktop'>
            <Button>
                <div className='theme-link' onClick={() => {setOpen(true)}}>
                    {fileName ?? file?.url}
                </div>
            </Button>
            <Popup
                open={open}
                closeOnDocumentClick
                modal
                nested
                onClose={() => {
                    setOpen(false)
                }}
            >
                <Frame>
                    <iframe title='Resume' src={file?.url}/>
                </Frame>
            </Popup>
        </div>
        <div className='mobile'>
            <File node={{fileName, fileEntry}}/>
        </div>
    </Div>
  )
}

const Div = styled.div(props => {
    return css`
        .desktop {
            display: none;
            @media(${breakpoints.laptop}) {
                display: initial;
            }
        }
        .mobile {
            display: initial;
            @media(${breakpoints.laptop}) {
                display: none;
            }
        }
    `
})

const Button = styled.div(props => {
    return css`
        display: flex;
        flex-wrap: wrap;
        max-width: ${sizes.constrainWidth};
        padding: 0 0.5rem;
        margin: auto;
        @media(${breakpoints.phoneLarge}) {
            padding: 0 3rem;
        }
        div {
            text-decoration: none;
            // color: ${colors.link};
            font-weight: 600;
            transition: 0.4s;
            cursor: pointer;
        }
    `
})

const Frame = styled.div(props => {
  return css`
    display: flex;
    flex-wrap: wrap;
    margin: auto;
    background: ${colors.secondary};
    height: 65vh;
    iframe {
        width: 100%;
    }
  `
})
