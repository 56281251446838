import React from 'react'
import { css } from '@emotion/core'
import styled from '@emotion/styled'
import { TiArrowSortedDown, TiArrowSortedUp } from 'react-icons/ti'
import Accordion from '../accordion'
import BlockContent from '../block-content'
import { sizes, breakpoints } from '../../styles/variables'

export default function AccordionType(props) {
    const { title, accordionContent } = props?.node

    return (
        <Div>
            <Accordion
                title={title}
                openIcon={TiArrowSortedDown}
                closedIcon={TiArrowSortedUp}
            >
                <BlockContent blocks={accordionContent}/>
            </Accordion>
        </Div>
    )
}

const Div = styled.div(props => {
    return css`
        max-width: ${sizes.constrainWidth};
        padding: 0 0.5rem;
        margin: auto;
        @media(${breakpoints.phoneLarge}) {
            padding: 0 3rem;
        }
    `
})