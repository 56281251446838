import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { css } from '@emotion/core'
import styled from '@emotion/styled'
import ReactPlayer from 'react-player'
import { FaPlayCircle } from 'react-icons/fa'
import { sanityFile } from '../../../../utils'

const query = graphql`
  query {
    allSanityFileAsset {
      nodes {
        assetId
        url
        originalFilename
      }
    }
  }
`

export default function VideoUpload(props) {
  const { video } = props?.node
  const videoFile = sanityFile(video?.asset?._ref || video?.asset?.id, useStaticQuery(query))

  return (
    <Section>
      <ReactPlayer
        url={videoFile?.url}
        style={videoStyles.videoPlayer}
        controls={true}
        playing={true}
        light={true}
        playIcon={
          <div className='play-button'>
            <FaPlayCircle/>
          </div>
        }
      />
    </Section>
  )
}

const Section = styled.section(props => {
  return css`
    width: 100%;
    height: 365px;
    display: flex;
    margin: 0 auto;
    text-align: center;
    .play-button {
      background: white;
      opacity: 0.75;
      border-radius: 100%;
      svg {
        display: flex;
        align-items: center;
        color: black;
        font-weight: bold;
        font-size: 5rem;
        cursor: pointer;
      }
    }
  `
})

const videoStyles = {
  videoPlayer: {
    margin: `0 auto`,
    frameborder: `0`,
  },
}
