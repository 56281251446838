import React from 'react'
import { css } from '@emotion/core'
import styled from '@emotion/styled'

export default function Break(props) {
    return (
        <Div height={props?.node?.height}/>
    )
}

const Div = styled.div(props => {
    return css`
        width: 100%;
        height: ${props?.height}rem;
    `
})