import React from 'react'
import { css } from '@emotion/core'
import styled from '@emotion/styled'
import Img from 'gatsby-image'
import { getFluidGatsbyImage } from 'gatsby-source-sanity'
import { colors } from '../styles/variables'
import config from '../../../config.json'

export default function GatsbyImage({image, maxWidth}) {
    return (
        <>
            <Img fluid={getFluidGatsbyImage(
                image?.asset?._ref || image?.asset?._id,
                { maxWidth: maxWidth, quality: 100 },
                config
            )}/>
            {/* <Background maxWidth={maxWidth}/> */}
        </>
    )
}

const Background = styled.div(props => {
    return css`
        width: ${props?.maxWidth}px;
        height: 100%;
        background: ${colors.body};
    `
})