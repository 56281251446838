import React from 'react'
import Image from 'gatsby-image'
import { getFluidGatsbyImage } from 'gatsby-source-sanity'
import Carousel from '../carousel'
import config from '../../../../config'

export default function Slider(props) {
  const { numSlides, autoplay, slide: panels } = props?.node

  function getSlides(panels) {
    const slides = panels.map(panel => {
      return {
        fluid: getFluidGatsbyImage(
          panel?.image?.asset?._ref || panel?.image?.asset?.id,
          { maxWidth: 1200 },
          config
        )
      }
    })
    return slides
  }

  const slideStyles = {
    minHeight: '400px',
    width: '100%',
    background: '#333',
    fontSize: '34px',
  }

  return (
    <Carousel numSlides={numSlides} autoplay={autoplay}>
      {getSlides(panels).map((slide, index) => {
        return <Image key={`${index}`} css={slideStyles} fluid={slide?.fluid} />
      })}
    </Carousel>
  )
}