import React from 'react'
import { css } from '@emotion/core'
import styled from '@emotion/styled'

export default function Error() {
    return (
        <Central>
            An error occurred.
        </Central>
    )
}

const Central = styled.div(props => {
    return css`
        display: flex;
        justify-content: center;
        align-items: center;
        margin: auto;
    `
})