import React from 'react'
import { css } from '@emotion/core'
import BlockContent from '../block-content'
import { sizes, breakpoints } from '../../styles/variables'

export default function Flex(props) {
    return (
        <BlockContent
            css={styles(props?.node)}
            blocks={props?.node?.flexContent}
        />
    )
}

const styles = ({flexDirection, gap}) => css`
    display: flex;
    flex-wrap: wrap;
    flex-direction: ${flexDirection};
    gap: ${gap}rem;
    max-width: ${sizes.constrainWidth};
    padding: 0 0.5rem;
    margin: auto;
    @media(${breakpoints.phoneLarge}) {
        padding: 0 3rem;
    }
    h1, h2, h3, h4, h5, h6, p {
        margin: 0;
        max-width: none;
        padding: 0 0.5rem;
        @media(${breakpoints.phoneLarge}) {
            padding: 0;
        }
    }
    div {
        max-width: 500px;
        padding: 0 !important;
    }
`
