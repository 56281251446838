import React from 'react'
import Slider from 'react-slick'
import { css } from '@emotion/core'
import { colors } from '../styles/variables'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

export default function Carousel(props) {
  const { children, numSlides, numSlidesMobile, autoplay } = props

  const settings = {
    dots: true,
    infinite: true,
    arrows: false,
    autoplay: autoplay,
    autoplaySpeed: 500,
    speed: 500,
    slidesToShow: (typeof window !== 'undefined' && window.screen.width > 768 ? numSlides : numSlidesMobile || numSlides) || 1,
    slidesToScroll: 1
  }

  if (!children) return null

  return (
    <div css={styles}>
      <Slider {...settings}>{children}</Slider>
    </div>
  )
}

const styles = css`
  color: #fff;
  .slick-dots {
    bottom: 10px;
    .slick-active {
      button {
        :before {
          opacity: 1;
          background-color: ${colors.white};
        }
      }
    }
    li {
      background: ${colors.secondary};
      margin: 0px;
      height: 22px;
      width: 22px;
      :first-of-type {
        border-radius: 4px 0 0 4px;
      }
      :last-of-type {
        border-radius: 0px 4px 4px 0;
      }
      button {
        :before {
          opacity: 1;
          content: '';
          background-color: ${colors.primary};
          border-radius: 50%;
          width: 12px;
          height: 12px;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }
  }
`
