import React from 'react'
import Projects from './projects'
import Contact from './contact'
import Construction from './construction'
import Error from './error'

export default function Blocks(props) {
    function getType(type) {
        try {
            switch (type) {
                case 'Projects':
                    return <Projects/>
                case 'Contact':
                    return <Contact/>
                case 'Construction':
                    return <Construction/>
                default:
                    return <Error/>
            }
          } catch (e) {
            return <Error/>
          }
    }

    return getType(props?.node?.type)
}