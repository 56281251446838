import React from 'react'
import { css } from '@emotion/core'
import styled from '@emotion/styled'
import BlockContent from '../block-content'

export default function TextContent(props) {
    const {
        topHeading,
        topHeadingAlign,
        heading,
        headingAlign,
        subHeading,
        subHeadingAlign,
        text,
        textAlign
    } = props?.node

    return (
        <Div
            topHeadingAlign={topHeadingAlign}
            headingAlign={headingAlign}
            subHeadingAlign={subHeadingAlign}
            textAlign={textAlign}
        >
            {topHeading && <h3 className='top'>{topHeading}</h3>}
            {heading && <h1 className='main'>{heading}</h1>}
            {subHeading && <h2 className='sub'>{subHeading}</h2>}
            {text && <div className='text'><BlockContent blocks={text}/></div>}
        </Div>
    )
}

const Div = styled.div(props => {
    const {
        topHeadingAlign,
        headingAlign,
        subHeadingAlign,
        textAlign
    } = props

    return css`
        .top {
            ${topHeadingAlign && `text-align: ${topHeadingAlign};`}
        }
        .main {
            ${headingAlign && `text-align: ${headingAlign};`}
        }
        .sub {
            ${subHeadingAlign && `text-align: ${subHeadingAlign};`}
        }
        .text {
            ${textAlign && `text-align: ${textAlign};`}
        }
    `
})