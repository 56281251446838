import React, { useState } from 'react'
import { css } from '@emotion/core'
import styled from '@emotion/styled'
import { colors } from '../styles/variables'

export default function Accordion(props) {
    const { title, children } = props

    const [active, setActive] = useState(false)

    return (
        <Div>
            <div className='title-bar theme'>
                <div>{title}</div>
                <div className='arrow' onClick={() => {setActive(!active)}}>
                    {active ? <props.closedIcon/> : <props.openIcon/>}
                </div>
            </div>
            {active &&
            <div className='accordion-content theme'>
               {children} 
            </div>}
        </Div>
    )
}

const Div = styled.div(props => {
    return css`
        .title-bar {
            display: flex;
            justify-content: space-between;
            padding: calc(1rem - 10px);
            .arrow {
                font-size: 1.5rem;
                cursor: pointer;
            }
        }
        .accordion-content {
            padding: calc(1rem - 10px);
        }
    `
})