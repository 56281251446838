import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { css } from '@emotion/core'
import styled from '@emotion/styled'
import { TiArrowSortedDown, TiArrowSortedUp } from 'react-icons/ti'
import Accordion from '../../accordion'
import Panel from '../../panel'
import BlockContent from '../index'
import GatsbyLink from '../../gatsby-link'
import { breakpoints, sizes } from '../../../styles/variables'

const query = graphql`
    query projectQuery {
        sanityProjects {
            projects {
                title
                subtitle
                links: _rawLinks(resolveReferences: { maxDepth: 5 })
                description: _rawDescription(resolveReferences: { maxDepth: 10 })
            }
        }
    }
`

export default function Projects() {
    const queryData = useStaticQuery(query)
    const { projects } = queryData?.sanityProjects
    return (
        <Div>
            <div className='desktop'>
                {projects.map((project, index) => {
                    const { title, subtitle, links, description } = project
                    return (
                        <Accordion
                            key={`project-${index}`}
                            title={title}
                            openIcon={TiArrowSortedDown}
                            closedIcon={TiArrowSortedUp}
                        >
                            {subtitle && <h4>{subtitle}</h4>}
                            {links.length !== 0 &&
                            <div className='links'>
                                {links.map((lnk, index) => {
                                    return (
                                        <GatsbyLink key={`${lnk.text || lnk.link}-${index}`} link={lnk.link} text={lnk.text || lnk.link}/>
                                    )
                                })}
                            </div>}
                            <BlockContent
                                renderContainerOnSingleChild
                                blocks={description}
                            />
                        </Accordion>
                    )
                })}
            </div>
            <div className='mobile'>
                {projects.map((project, index) => {
                    const { title, subtitle, links, description } = project
                    return (
                        <Panel
                            key={`project-${index}`}
                            title={title}
                            border={true}
                            constantFullscreen={true}
                            closeFont={3}
                            iconRotate={270}
                            closeRotate={270}
                            iconPadding={true}
                            openIcon={TiArrowSortedDown}
                            closedIcon={TiArrowSortedUp}
                        >
                            {subtitle && <h4>{subtitle}</h4>}
                            {links.length !== 0 &&
                            <div className='links'>
                                {links.map((lnk, index) => {
                                    return (
                                        <GatsbyLink key={`${lnk.text || lnk.link}-${index}`} link={lnk.link} text={lnk.text || lnk.link}/>
                                    )
                                })}
                            </div>}
                            <BlockContent
                                renderContainerOnSingleChild
                                blocks={description}
                            />
                        </Panel>
                    )
                })}
            </div>
        </Div>
    )
}

const Div = styled.div(props => {
    return css`
        max-width: ${sizes.constrainWidth};
        padding: 0 0.5rem;
        margin: auto;
        @media(${breakpoints.phoneLarge}) {
            padding: 0 3rem;
        }
        .desktop {
            display: none;
            h4 {
                font-weight: normal;
                margin: 0;
                padding: 10px 0;
            }
            .links {
                a {
                    padding: 0 5px;
                    text-decoration: none;
                    font-weight: 600;
                    transition: 0.4s;
                }
            }
            @media(${breakpoints.laptop}) {
                display: flex;
                flex-direction: column;
            }
        }
        .mobile {
            display: flex;
            flex-direction: column;
            > div {
                width: 100%;
            }
            @media(${breakpoints.tablet}) {
                flex-direction: row;
                > div {
                    width: 50%;
                }
            }
            h4 {
                font-weight: normal;
                margin: 0;
                padding: 10px 0;
            }
            .links {
                a {
                    padding: 0 5px;
                    text-decoration: none;
                    font-weight: 600;
                    transition: 0.4s;
                }
            }
            @media(${breakpoints.laptop}) {
                display: none;
            }
        }
    `
})