import React from 'react'
import { css } from '@emotion/core'
import styled from '@emotion/styled'

export default function Construction() {
  return (
    <Div>
    <p>Under Construction</p>
    </Div>
  )
}

const Div = styled.div(props => {
  return css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 5vh;
  `
})