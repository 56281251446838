import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { css } from '@emotion/core'
import styled from '@emotion/styled'
import GatsbyLink from '../gatsby-link'
import { colors, breakpoints, sizes } from '../../styles/variables'
import { sanityFile } from '../../../../utils'

const query = graphql`
  query {
    allSanityFileAsset {
      nodes {
        assetId
        url
        originalFilename
      }
    }
  }
`

export default function File(props) {
  const { fileName, fileEntry } = props?.node
  const file = sanityFile(fileEntry?.asset?._ref || fileEntry?.asset?.id, useStaticQuery(query))

  return (
    <Div>
      {file && <GatsbyLink className='theme-link' link={file?.url} text={fileName}/>}
    </Div>
  )
}

const Div = styled.div(props => {
  return css`
    display: flex;
    flex-wrap: wrap;
    max-width: ${sizes.constrainWidth};
    padding: 0 0.5rem;
    margin: auto;
    @media(${breakpoints.phoneLarge}) {
        padding: 0 3rem;
    }
    a {
      text-decoration: none;
      font-weight: 600;
      transition: 0.4s;
    }
  `
})
